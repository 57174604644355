import React from "react";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import kv from "../images/kv.jpg";
import video_meeting from "../images/video_meeting.png";
import website_normal from "../images/website_normal.png";
import smartphone_app from "../images/smartphone_app.png";
import tw from "tailwind.macro";
import styled from "@emotion/styled";

function IndexPage() {
  const LinkButton = styled.button`
    ${tw`text-white font-bold py-2 px-4 rounded`}
    background-color: #ffd295;

    &:hover {
      background-color: #ffb54f;
    }
  `;
  const SkillCards = styled.div`
    display: flex;
    justify-content: center;
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));

    > div {
      text-align: center;
      background-color: #ffd295;
      width: 33.333333%;
      border-radius: 0.4rem;
      padding: 0.75rem;
      margin: 0.5rem;
      --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    @media screen and (max-width: 780px) {
      display: block;
      margin: 0;
      > div {
        &:first-child {
          margin-top: 1rem;
        }
        width: 95%;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  `;

  return (
    <Layout>
      <SEO
        keywords={[
          `Web制作`,
          `福岡`,
          `Android`,
          `iPhone`,
          `アプリケーション開発`,
        ]}
        title="Home"
        description="株式会社CatDrivenは福岡でシステム開発・アプリ開発・Webサイト制作を中心としている会社です。"
      />
      <section>
        <img className="block w-full mx-auto mb-8" src={kv} />
      </section>
      <section className="text-center m-10">
        <h2 className="inline-block p-3 mb-4 text-2xl font-bold">
          わたしたちについて
        </h2>
        <div>
          <p className="leading-loose">
            CatDrivenは福岡でシステム開発・アプリ開発・Webサイト制作を中心としている会社です。
          </p>
          <p className="text-right mx-10 my-2">
            <LinkButton onClick={() => navigate("/about")}>About</LinkButton>
          </p>
        </div>
      </section>
      <section className="text-center m-10">
        <h2 className="inline-block p-3 mb-4 text-2xl font-bold">
          お手伝いできることがありますか？
        </h2>
        <SkillCards>
          <div>
            <img className="h-32 inline-block" src={website_normal} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Web Development</div>
              <p className="text-gray-700 text-base">
                WordPressなどの各種CSMを用いたWebサイトの制作や、
                TypeScript/Python/PHP等を用いたWebアプリケーションの開発も承っております。
                改修・運用も承っておりますので、まずはご相談ください。
              </p>
            </div>
          </div>
          <div>
            <img className="h-32 inline-block" src={smartphone_app} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">App Development</div>
              <p className="text-gray-700 text-base">
                iPhone/Androidアプリケーションの開発を行います。
                ネイティブはもちろん、FlutterやIonicなどのクロスプラットフォームを利用したユーティリティ系からUnityやCocos2d-xを利用したゲームまで幅広く承っております。
              </p>
            </div>
          </div>
          <div>
            <img className="h-32 inline-block" src={video_meeting} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">コンサルティング</div>
              <p className="text-gray-700 text-base">
                貴社の経営戦略を基にIT戦略策定からIT投資計画に沿ったソリューションのご提案からシステム開発・運用までを行います。
                まずはお気軽にご相談ください。
              </p>
            </div>
          </div>
        </SkillCards>
        <div className="m-10">
          <LinkButton onClick={() => navigate("/contact")}>
            お問い合わせはこちらから
          </LinkButton>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
